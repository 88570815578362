'use client';

import { SlideshowFragment } from '@graphql/generated';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ExternalLink } from '../ExternalLink';
import { InternalLink } from '../InternalLink';
import { SlideshowElement } from '../SlideshowElement';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type Props = {
  block: SlideshowFragment;
};

export const Slideshow = ({ block }: Props) => {
  const { slides, id, title, cssClass, fadeEffect } = block;
  const delay = 4000;

  return (
    <div className={`slideshow ${cssClass}`} key={id}>
      <div className="slideshow__title">{title}</div>
      <Swiper
        speed={(cssClass || '').indexOf('main-slideshow_mobile') > 0 ? 500 : 4000}
        effect={fadeEffect ? 'fade' : 'slide'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        loop={true}
        fadeEffect={{ crossFade: true }}
        autoplay={{ delay }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="swiper-wrapper"
      >
        {slides?.map((slide, i) => {
          return (
            <SwiperSlide key={i}>
              {slide.externalLink ? (
                <ExternalLink
                  id={id}
                  className="slideshow__link"
                  target="_blank"
                  rel="noopener"
                  externalLink={slide.externalLink}
                >
                  <SlideshowElement slide={slide} />
                </ExternalLink>
              ) : slide.internalLink ? (
                <InternalLink
                  className="slideshow__link"
                  internalLink={slide.internalLink}
                  showTitle={false}
                >
                  <SlideshowElement slide={slide} />
                </InternalLink>
              ) : (
                <SlideshowElement slide={slide} />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
