'use client';

import { Bundle, Sku } from '@commercelayer/sdk';
import { Loading } from '@components/layout/Loading';
import { ProductFragment, SiteLocale } from '@graphql/generated';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { trackProductListView } from '@lib/utils/gtmUtils';
import { getSkusInMarket } from '@lib/utils/pathUtils';
import { ProductAndBundleDataType } from '@model/product';
import { detectFinalPath } from '@utils/index';
import { getBundleData, getProductData } from '@utils/product-utils';
import { usePathname } from 'next/navigation';
import { ActionDispatch, AnyActionArg, useEffect, useRef, useState } from 'react';
import { BundleBox } from './BundleBox';
import { ProductBox } from './ProductBox';

type Props = {
  locale: SiteLocale;
  baseUrl: string;
  products: ProductFragment[];
  isRelated?: boolean;
  cssClass?: string;
};

export const Products = ({
  locale,
  baseUrl,
  products,
  isRelated = false,
  cssClass = '',
}: Props) => {
  /**
   * Filter products by market.
   * TODO: It's not the best here.
   */
  const market = baseUrl.split('/')[1];
  const currency = baseUrl.split('/')[2];
  const skusEnabled = getSkusInMarket(market, currency);
  const seen = new Set();
  const filteredProducts = products.filter((product) => {
    //filter duplicated id
    if (seen.has(product.id)) {
      return false;
    }
    seen.add(product.id);
    //filter disabled sku
    return skusEnabled.includes(product.skuCode);
  });
  /**
   * End filter products by market.
   */

  if (filteredProducts.length == 0) {
    return null;
  }

  return (
    <ProductList
      cssClass={cssClass}
      locale={locale}
      baseUrl={baseUrl}
      products={filteredProducts}
      isRelated={isRelated}
    />
  );
};

const ProductList = ({ cssClass, locale, baseUrl, products, isRelated = false }: Props) => {
  let visibleList: any[] = [];
  let scrollTimeout: NodeJS.Timeout | undefined;

  const pathname = usePathname();
  const [addToCartloading, setAddToCartLoading] = useState(false);
  const [comLayerProducts, setComLayerProducts] = useState<Sku[]>([]);
  const [comLayerBundles, setComLayerBundles] = useState<Bundle[]>([]);
  const { client, dispatch } = useAppContext() as {
    client: AppCommerceLayerClient;
    dispatch: ActionDispatch<AnyActionArg>;
  };
  const [id, setId] = useState<number>(-1);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (client) {
      const gtmProductListElements = document.querySelectorAll('.gtm-product-list');
      gtmProductListElements.forEach((el, index) => {
        if (el === ref.current) {
          setId(index + 1);
        }
      });
      getComLayerProducts(products.filter((p) => !p.isBundle).map((p) => p.skuCode));
      getComLayerBundles(products.filter((p) => p.isBundle).map((p) => p.skuCode));
    }
  }, [client, products.length]);

  const getComLayerProducts = async (skus: string[]) => {
    if (skus.length > 0) {
      setComLayerProducts(await client.getProductList(skus));
    }
  };

  const getComLayerBundles = async (skus: string[]) => {
    if (skus.length > 0) {
      setComLayerBundles(await client.getBundleList(skus));
    }
  };

  const addProductToCart = async (productData: ProductAndBundleDataType, indexGtm: number) => {
    setAddToCartLoading(true);
    try {
      // call this to get inventory.levels
      const product = await getProductData(
        client,
        productData.skuCode,
        productData.image,
        productData.url,
        productData.name
      );

      const result = await client.addToCart(product, indexGtm, 1, getItemListName());
      await finalizeAddToCart(result);
    } catch (error) {
      throw error;
    } finally {
      setAddToCartLoading(false);
    }
  };

  const addBundleToCart = async (productData: ProductAndBundleDataType, indexGtm: number) => {
    setAddToCartLoading(true);
    try {
      // call this to get inventory.levels
      const bundle = await getBundleData(
        client,
        productData.skuCode,
        productData.image,
        productData.url,
        productData.name
      );
      const result = await client.addBundleToCart(bundle, indexGtm, 1, getItemListName());
      await finalizeAddToCart(result);
    } catch (error) {
      throw error;
    } finally {
      setAddToCartLoading(false);
    }
  };

  const finalizeAddToCart = async (result: any) => {
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL', data: result });

    const order = await client.getOrder();
    dispatch({ type: 'SET_ORDER', data: order });

    const cartItems = client.countOrderProductsQuantity(order);
    dispatch({ type: 'SET_NUM_ITEMS', data: cartItems });
  };

  const onVisible = (productData: ProductAndBundleDataType, index: number) => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    visibleList.push({
      item_id: productData.skuCode,
      item_name: productData.name,
      index: index,
      price: productData.price?.amount_float,
    });
    scrollTimeout = setTimeout(() => {
      trackProductListView(getItemListName(), visibleList, productData.price?.currency_code);
      visibleList = [];
    }, 1000);
  };

  const getItemListName = () => detectFinalPath(pathname, locale) + '_position_' + id;

  const renderProductBox = (product: ProductFragment, sku: Sku, index: number) => (
    <div className={`product-list__item ${cssClass}`} key={product.id}>
      <ProductBox
        locale={locale}
        baseUrl={baseUrl}
        product={product}
        sku={sku}
        addToCart={(productData) => addProductToCart(productData, index)}
        onVisible={(productData) => onVisible(productData, index)}
        index={index}
        itemListName={getItemListName()}
      />
    </div>
  );

  const renderBundleBox = (product: ProductFragment, bundle: Bundle, index: number) => (
    <div className={`product-list__item ${cssClass}`} key={product.id}>
      <BundleBox
        locale={locale}
        baseUrl={baseUrl}
        product={product}
        bundle={bundle}
        addToCart={(bundleData) => addBundleToCart(bundleData, index)}
        onVisible={(bundleData) => onVisible(bundleData, index)}
        index={index}
        itemListName={getItemListName()}
      />
    </div>
  );

  const productsElement = (products || []).map((product, index) => {
    if (product.isBundle) {
      const bundle = comLayerBundles.find((bundle) => product.skuCode === bundle.code);
      return renderBundleBox(product, bundle, index);
    } else {
      const sku = comLayerProducts.find((pro) => product.skuCode === pro.code);
      return renderProductBox(product, sku, index);
    }
  });

  return (
    <div className="gtm-product-list" ref={ref}>
      {addToCartloading && <Loading locale={locale} />}
      {isRelated ? (
        <div className={`related-product ${cssClass}`}>
          <div className="related-product__title">{t(locale, 'you_might_also_like')}</div>
          <div className="related-product__content">
            <div className="product-list">{productsElement}</div>
          </div>
        </div>
      ) : (
        <div className={`product-list ${cssClass}`}>{productsElement}</div>
      )}
    </div>
  );
};
